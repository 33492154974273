import React, { FC } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

type MoodboardModalType = {
  imageUrls: string[];
};

export const MoodboardModal: FC<MoodboardModalType> = ({ imageUrls }) => {
  return (
    <StyledMoodboardModal>
      <Carousel infiniteLoop>
        {imageUrls.map((url) => (
          <img className='main-image' key={url} src={url} alt={url} />
        ))}
      </Carousel>
    </StyledMoodboardModal>
  );
};

const StyledMoodboardModal = styled.div`
  margin: auto 0px;
  padding: 30px;
`;
