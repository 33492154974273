import React, { FC } from 'react';

export const HeartIcon: FC<{ filled: boolean | null; onClick: () => void }> = ({
  filled,
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill={filled ? 'red' : 'none'}
    >
      <path
        d='M29.2913 12.6118C28.7805 12.1008 28.1741 11.6955 27.5066 11.4189C26.8392 11.1423 26.1238 11 25.4013 11C24.6788 11 23.9634 11.1423 23.2959 11.4189C22.6285 11.6955 22.022 12.1008 21.5113 12.6118L20.4513 13.6718L19.3913 12.6118C18.3596 11.5801 16.9603 11.0005 15.5013 11.0005C14.0423 11.0005 12.643 11.5801 11.6113 12.6118C10.5796 13.6435 10 15.0428 10 16.5018C10 17.9609 10.5796 19.3601 11.6113 20.3918L12.6713 21.4518L20.4513 29.2318L28.2313 21.4518L29.2913 20.3918C29.8023 19.8811 30.2076 19.2746 30.4842 18.6072C30.7608 17.9397 30.9031 17.2243 30.9031 16.5018C30.9031 15.7793 30.7608 15.0639 30.4842 14.3965C30.2076 13.729 29.8023 13.1226 29.2913 12.6118V12.6118Z'
        stroke={filled ? 'red' : '#5C5C5C'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
