import React, { FC } from 'react';

const width = 10;
const height = 6;
export const ArrowIcon: FC<{ rotation?: 0 | 90 | 180 | 270 | 360 }> = ({
  rotation,
}) => {
  return (
    <svg
      style={{
        rotate: rotation ? `${rotation}deg` : '',
      }}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
    >
      <path
        d='M0.195704 5.51811C0.133669 5.45634 0.0844503 5.38295 0.0508681 5.30216C0.0172859 5.22137 -2.24447e-07 5.13476 -2.20624e-07 5.04729C-2.16801e-07 4.95982 0.0172859 4.87321 0.0508681 4.79242C0.0844503 4.71163 0.133669 4.63825 0.195704 4.57647L4.62422 0.15558C4.67353 0.106262 4.73209 0.0671345 4.79656 0.0404382C4.86102 0.0137418 4.93013 2.15503e-07 4.99993 2.18554e-07C5.06972 2.21605e-07 5.13883 0.0137418 5.2033 0.0404382C5.26777 0.0671346 5.32633 0.106262 5.37563 0.15558L9.80416 4.57647C10.0653 4.83715 10.0653 5.25743 9.80416 5.51811C9.54303 5.77879 9.12202 5.77879 8.8609 5.51811L4.99726 1.66645L1.13363 5.52343C0.877835 5.77879 0.451503 5.77879 0.195704 5.51811Z'
        fill='#404D6E'
      />
    </svg>
  );
};
