import React, { FC } from 'react';

export const ArkivetLogo: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <svg
        width='98'
        height='29'
        viewBox='0 0 98 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M36.6836 21H38.2416L39.5526 17.086H45.1576L46.4876 21H48.1216L43.5236 7.41499H41.3576L36.6836 21ZM42.3646 8.78299L44.7206 15.775H40.0086L42.3646 8.78299Z'
          fill='#1C1D1E'
        />
        <path
          d='M50.3983 21H51.9753V15.547C51.9753 13.096 53.2483 12.469 55.2433 12.374V10.892C53.4573 10.949 52.6023 11.728 51.9753 12.849V11.063H50.3983V21Z'
          fill='#1C1D1E'
        />
        <path
          d='M57.5419 21H59.1189V15.851L63.5269 21H65.4459L60.8289 15.68L65.0849 11.063H63.2419L59.1189 15.642V6.31299H57.5419V21Z'
          fill='#1C1D1E'
        />
        <path
          d='M67.3768 21H68.9538V11.063H67.3768V21ZM68.1368 8.99199C68.7068 8.99199 69.1818 8.51699 69.1818 7.94699C69.1818 7.37699 68.7068 6.90199 68.1368 6.90199C67.5668 6.90199 67.0918 7.37699 67.0918 7.94699C67.0918 8.51699 67.5668 8.99199 68.1368 8.99199Z'
          fill='#1C1D1E'
        />
        <path
          d='M74.6705 21H76.6845L80.2565 11.063H78.6225L75.7155 19.404L72.7705 11.063H71.0605L74.6705 21Z'
          fill='#1C1D1E'
        />
        <path
          d='M86.1186 21.171C88.3606 21.171 90.0136 20.126 90.3556 18.131H88.7786C88.5696 19.29 87.6766 19.86 86.1376 19.86C84.1046 19.86 83.0216 18.606 82.9456 16.345H90.4316V15.832C90.4316 12.279 88.2656 10.892 85.9666 10.892C83.2306 10.892 81.3116 12.982 81.3116 15.984V16.136C81.3116 19.195 83.3066 21.171 86.1186 21.171ZM83.0026 15.072C83.2686 13.305 84.3516 12.203 85.9666 12.203C87.6006 12.203 88.6646 13.02 88.8166 15.072H83.0026Z'
          fill='#1C1D1E'
        />
        <path
          d='M95.8725 21.133C96.5185 21.133 96.8985 21.038 97.2215 20.924V19.594C96.8795 19.727 96.4995 19.803 96.0055 19.803C95.2075 19.803 94.7705 19.328 94.7705 18.359V12.393H97.0885V11.063H94.7705V8.80199H93.1935V11.063H91.7685V12.393H93.1935V18.511C93.1935 20.069 94.0675 21.133 95.8725 21.133Z'
          fill='#1C1D1E'
        />
        <rect x='0.5' y='0.5' width='26' height='28' stroke='#1C1D1E' />
        <line x1='1' y1='14.5' x2='27' y2='14.5' stroke='black' />
        <circle cx='13.5' cy='21.5' r='2' stroke='#1C1D1E' />
        <circle cx='13.5' cy='7.5' r='2' stroke='#1C1D1E' />
      </svg>
    </div>
  );
};
