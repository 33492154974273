import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from '../sanity';

export type FurnitureType = {
  customerLike: boolean | null;
  customerNote: string;
  count: number;
  description: string;
  furniture_link: string;
  imageUrl: string;
  model_name: string;
  price: number;
  provider: {
    homepage: string;
    title: string;
  };
  type?: string;
  tags: string[];
};

export type RoomFurnitureType = {
  room: string;
  moodboard_images: string[];
  room_furniture: FurnitureType[];
};

type ProjectType = {
  _id: string;
  _updatedAt: Date;
  project_name: string;
  project_notes: string;
  slug: string;
  budget: number;
  rooms_with_furniture: RoomFurnitureType[];
};

export const useProject = (slug?: string) => {
  return useQuery<ProjectType>(
    'project',
    async () => {
      const response = await client.fetch(
        `*[_type == "project" && slug.current match "${slug}" &&  !(_id in path('drafts.**'))] {
          _id,
          _updatedAt,
          project_name,
          project_notes,
          budget,
          rooms_with_furniture {
            "room": room->room_name,
            "moodboard_images": moodboard_images[].asset->url,
            room_furniture[] {
              customerLike,
              customerNote,
              count,
              ...furniture-> {
                description,
                furniture_link,
                "imageUrl": image.asset->url,
                model_name,
                price,
                provider-> {
                  homepage,
                  title
                },
                "type": type->.type,
                "tags": tags[]->.title
              }
            }
          }[]
        }[0]`
      );
      return response;
    },
    {
      enabled: !!slug,
    }
  );
};

type UpdateLikeType = {
  projectId: string;
  roomIndex: number;
  furnitureIndex: number;
  value: boolean | string;
};
export const useUpdateCustomerLike = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, string, UpdateLikeType, unknown>(
    async (values: UpdateLikeType) => {
      const data = await client
        .patch(values.projectId)
        .set({
          [`rooms_with_furniture[${values.roomIndex}].room_furniture[${values.furnitureIndex}].customerLike`]:
            values.value,
        })
        .commit();
      queryClient.invalidateQueries('project');

      return data;
    }
  );
};
