import React, { FC } from 'react';
import styled from 'styled-components';

export const Loader: FC<{ size?: number }> = ({ size = 25 }) => {
  return (
    <StyledLoader size={size}>
      <div />
    </StyledLoader>
  );
};

const StyledLoader = styled.div<{ size?: number }>`
  display: grid;
  justify-content: center;
  align-items: center;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  div {
    width: ${({ size }) => `${size}px`};
    aspect-ratio: 1;
    border: 5px solid #f3f3f3;
    border-top: 5px solid ${({ theme }) => theme.color.interactive};
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
`;
