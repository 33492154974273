export const fonts = `
  @font-face {
    font-family: SpaceGroteskLight;
    font-weight: lighter;
    src: url("./fonts/SpaceGrotesk-Light.ttf");
  }
  @font-face {
    font-family: SpaceGroteskRegular;
    font-weight: normal;
    src: url("./fonts/SpaceGrotesk-Regular.ttf");
  }
  @font-face {
    font-family: SpaceGroteskBold;
    font-weight: bold;
    src: url("./fonts/SpaceGrotesk-Bold.ttf");
  }
`;
