import { FC } from 'react';
import styled from 'styled-components';
import { FurnitureType } from '../../services/api/useSanity';
import { numberWithCommas } from '../../utils/number';
import { ExternalLinkIcon } from '../icons/ExternalLinkIcon';
import { HeartIcon } from '../icons/HeartIcon';
import { Loader } from '../Loader';

export const FurnitureModal: FC<{
  furniture: FurnitureType;
  heartClick: () => void;
  isLoadingLike: boolean;
}> = ({ furniture, heartClick, isLoadingLike }) => {
  const umami = window.umami;
  return (
    <StyledFurnitureModal>
      <img src={furniture?.imageUrl} alt={furniture?.model_name} />
      <a
        onClick={() => {
          umami.track('furniture_external_link_click', { furniture_model: furniture?.model_name });
        }}
        href={furniture.furniture_link}
        rel="noreferrer"
        target="_blank"
      >
        <div className="product-page-block flex-between-center">
          <p>Besøk produkt siden</p>
          {furniture.furniture_link && <ExternalLinkIcon />}
        </div>
      </a>
      <div className="info-container">
        <div className="title flex-between-center">
          <p className="text-muted">{furniture.provider.title}</p>
          <div className="icon">
            {isLoadingLike ? <Loader size={30} /> : <HeartIcon onClick={heartClick} filled={furniture.customerLike} />}
          </div>
        </div>
        <div className="info flex-between-center">
          <h2>{furniture.model_name}</h2>
          <h2>Pris: {numberWithCommas(furniture.price)} kr</h2>
        </div>
        <p>{furniture.description}</p>
        {furniture.tags && (
          <div className="tag-chips">
            <p>
              <b>Tags</b>
            </p>
            {furniture.tags.map(tag => (
              <StyledChip key={tag}>{tag}</StyledChip>
            ))}
          </div>
        )}
        {furniture.customerNote && (
          <div className="customer-note">
            <b>Notat fra designer</b>
            <p>{furniture.customerNote}</p>
          </div>
        )}
      </div>
    </StyledFurnitureModal>
  );
};

const StyledChip = styled.div`
  display: inline-block;
  width: fit-content;
  background-color: #e9ebeb;
  color: black;
  padding: 8px 18px;
  border-radius: 16px;
  :not(:first-of-type) {
    margin-left: 5px;
  }
`;

const StyledFurnitureModal = styled.div`
  height: 70vh;
  img {
    display: block;
    padding: 20px;
    max-width: 40%;
    aspect-ratio: 1;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 800px) {
      max-width: 70%;
    }
  }
  a {
    text-decoration: none;
    .product-page-block {
      padding: 10px;
      background-color: black;
      color: white;
    }
  }
  .info-container {
    padding: 20px;
    .title {
      p {
        margin: 0;
      }
    }
    .info {
      h2 {
        font-weight: 700;
        font-size: 14px;
      }
    }
    .icon {
      min-width: 40px;
      min-height: 40px;
    }
    .tag-chips {
      p {
        margin-bottom: 2px;
      }
    }
    .customer-note {
      margin-top: 10px;
      p {
        margin-top: 2px;
        border: 1px solid #d3d3d3;
        border-radius: 4px;
        padding: 10px;
      }
    }
  }
`;
