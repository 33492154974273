import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { fonts } from './Fonts';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${fonts}
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    padding: 0;
    margin: 0;
  }
  
  html {
    box-sizing: border-box;
    font-size: 100%;
    overflow-y: scroll;
  }
  /* TODO: Global styles for elements from Figma */

  body {
    font-family: SpaceGroteskRegular;
  }

  h1, h2, a, b {
    font-family: SpaceGroteskRegular;
  }

  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    color: #2520EF;

}

  h2 {
    font-weight: 550;
    font-size: 18px;
    line-height: 23px;
  }

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  b {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .text-muted {
    font-family: SpaceGroteskRegular;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.color.muted};
  }

  .flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
