import { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';

type ModalType = {
  closeModal: () => void;
  children: ReactNode;
  width?: number | string;
  height?: number | string;
};

export const Modal: FC<ModalType> = ({
  closeModal,
  children,
  width,
  height,
}) => {
  useEffect(() => {
    function handleKey(event: any) {
      if (event.key === 'Escape') {
        closeModal();
      }
    }

    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [closeModal]);

  return (
    <ModalWrapper
      onClick={(ev) => {
        if ((ev.target as HTMLDivElement).closest('#modal-inner') !== null) {
          // Don't close modal if the click is inside Inner
          return;
        }
        closeModal();
      }}
    >
      <ModalInner id='modal-inner' width={width} height={height}>
        <div className='close-button' onClick={closeModal}>
          X
        </div>
        {children}
      </ModalInner>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div<{
  width?: number | string;
  height?: number | string;
}>`
  position: relative;
  width: 50vw;
  height: 95vh;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  overflow-y: scroll;
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1005;
  }

  @media screen and (max-width: 800px) {
    width: 95vw;
  }
`;
